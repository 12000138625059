
/* eslint-disable */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*//* eslint-disable */


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images


import React from "react";
import { useRef, useState, useEffect } from "react";

// core components
import { Grid, Card, CardHeader, CardContent, Container } from "@mui/material";

import ImgMergedCloud from "assets/images/KyLy/JoinedCloud_Merged.webp";
import taskProcImg from "assets/images/KyLy/TaskProcessor.webp";


import { Description, Email, Language } from "@mui/icons-material";


// nodejs library that concatenates classes
import styled from '@emotion/styled';



import createEngine, {
    DiagramModel,
    DefaultNodeModel,
    DefaultPortModel,
    DefaultLinkFactory,
    DefaultLinkModel,
    DiagramEngine,
    AbstractReactFactory,
    NodeModel,
    NodeModelGenerics,
    PortWidget,
    PortModelAlignment,
    LinkModel,
    PortModel
} from '@projectstorm/react-diagrams';
import gsap from 'gsap';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { AdvancedLinkFactory, AdvancedPortModel } from "components/diagrams/Links/AdvancedLink";
import { ImageNodeFactory, ImageNodeModel, ImageNodePortModel, ImageNodeWidget } from "components/diagrams/ImageNode/ImageNodeWidget";



export default function SectionPipelineDiagram() {

    const addIconNode = (model, icon, size, posX, posY) => {
        var node = new ImageNodeModel(icon, size);
        let port = node.addPort(new ImageNodePortModel(PortModelAlignment.RIGHT, 1));
        node.setPosition(posX, posY);
        model.addNode(node);
        return { node: node, port: port };
    };

    var engine = createEngine();
    var model = new DiagramModel();
    engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());
    engine.getNodeFactories().registerFactory(new ImageNodeFactory());

    let procNode = addIconNode(model, taskProcImg, 120, 270, 145);
    let procNodeInPort = procNode.node.addPort(new AdvancedPortModel(PortModelAlignment.LEFT, 0));

    let inputNodes = [
        addIconNode(model, ImgMergedCloud, 120, 20, 20),
        addIconNode(model, <Email />, 35, 20, 120),
        addIconNode(model, <Description />, 35, 20, 210),
        addIconNode(model, <Language />, 35, 20, 300)
    ];

    inputNodes.forEach(element => {
        model.addAll(element.port.link(procNodeInPort));
    });

    // load model into engine
    engine.setModel(model);


    const ContainerXYZ = styled.div`
        min-height: 350px;
        max-height: 1000px;
        background-size: 50px 50px;
        background-color: #172b4d;
        border-radius: 6px;
        display: flex;
            > * {
                width: 100%;
            }`;




    return (
        <MKBox position="relative" mt={{ xs: 0, lg: 4 }} mx={-2} sx={{ minHeight: "80vh" }}>
            <MKBox
                alt="background-pattern"
                position="absolute"
                top={0}
                left={0}
                width={{ xs: "auto", lg: "100%" }}
                height={{ xs: "100%", lg: "auto" }}
                opacity={0.6}
            />
            <Container>
                <Grid container>
                    <Grid item xs={12} lg={12}
                        sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}>
                        <MKTypography variant="h3" color="dark">
                            Event driven & scheduled task pipelines
                        </MKTypography>
                        <MKTypography variant="body2" color="dark" opacity={0.8}>
                            Text descsribing pipelines
                        </MKTypography>
                    </Grid>
                    <Grid container spacing={3} sx={{ paddingBottom: "50px" }}>
                        <Grid item xs={12} lg={12}>
                            <MKBox mb={1}>
                                <ContainerXYZ id="canvasContainer" style={{ pointerEvents: 'none' }} >
                                    <CanvasWidget engine={engine} />
                                </ContainerXYZ>
                            </MKBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox >
    );
}
