/*eslint-disable*/
import React from "react";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultCompanyFooter from "elements/footer/DefaultCompanyFooter";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import { AppConfig } from "global/config";
import routes from "routes";

export default function ErrorPage() {
    return (
        <>
            <DefaultNavbar
                routes={routes} />

            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container>
                        <Grid item md={12}>
                            <h1>404</h1>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>

            <DefaultCompanyFooter />
        </>
    );
}
