/* eslint-disable */
import React from "react";

import { Container, Grid } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SimpleBackgroundCard from "examples/Cards/BackgroundCards/SimpleBackgroundCard";

// Images
import consultingImage from "assets/images/software-consulting2.webp";
import auditImage from "assets/images/performance-audit.webp";
import codeReviewImage from "assets/images/code-review.webp";

export default function SectionKyberLyteServices() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container item xs={12} lg={6} justifyContent="center" mx="auto" textAlign="center">
                    <MKTypography variant="h2" mb={2}>
                        Our additional services
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                        We offer a range of professional services to help your business thrive in the digital landscape.
                    </MKTypography>
                </Grid>
                <Grid container spacing={5} justifyContent="space-between">
                    <Grid item xs={12} md={4} lg={4}>
                        <SimpleBackgroundCard
                            image={consultingImage}
                            label="Expert Guidance"
                            title="Software Consulting"
                            description="Our experienced consultants provide strategic advice to optimize your software development processes and align technology with your business goals."
                            action={{
                                type: "internal",
                                route: "/services/consulting",
                                label: "Learn More",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SimpleBackgroundCard
                            image={auditImage}
                            label="Optimize Performance"
                            title="Performance Audits"
                            description="We conduct thorough audits of your software systems to identify bottlenecks, improve efficiency, and enhance overall performance."
                            action={{
                                type: "internal",
                                route: "/services/performance-audits",
                                label: "Learn More",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SimpleBackgroundCard
                            image={codeReviewImage}
                            label="Quality Assurance"
                            title="Code Reviews"
                            description="Our expert developers perform comprehensive code reviews to ensure best practices, identify potential issues, and improve code quality."
                            action={{
                                type: "internal",
                                route: "/services/code-reviews",
                                label: "Learn More",
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={3}>
                        <SimpleBackgroundCard
                            label="Innovative Solutions"
                            title="In-house Products"
                            description="Discover our suite of in-house developed products designed to solve common business challenges and streamline your operations."
                            action={{
                                type: "internal",
                                route: "/products",
                                label: "Explore Products",
                            }}
                        />
                    </Grid> */}
                </Grid>
            </Container>
        </MKBox>
    );
}
