/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Popover from '@mui/material/Popover';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ImageHighlightCard({ image, color, title, text, caption }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        if (anchorEl) setAnchorEl(null);
        else setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);

    document.addEventListener("scroll", () => setAnchorEl(null));
    return (
        <>
            <Grid container alignItems="center" spacing={3} justifyContent="center">
                <Grid item lg={8} xs={5}>
                    <MKBox p={{ xs: 0, lg: 4 }}>
                        <MKBox
                            component="img"
                            src={image}
                            alt={title}
                            width="100%"
                            borderRadius="md"
                            shadow="md"

                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={handlePopoverOpen}
                        />
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={anchorEl}
                            sx={{ width: "80%" }}
                            onClose={handlePopoverClose}
                            onClick={handlePopoverClose}>
                            <img src={image} style={{ width: "700px", overflow: "scroll" }} />
                        </Popover>
                    </MKBox>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={3} justifyContent="center">
                <Grid item lg={8} xs={11}>
                    <MKTypography variant="h3" color={color} textAlign="center">
                        {caption}
                    </MKTypography>
                    <MKTypography variant="body2" color="text" my={3} textAlign="center">
                        {text}
                    </MKTypography>
                </Grid>
            </Grid>
        </>
    );
}

// Setting default props for the ImageHighlightCard
ImageHighlightCard.defaultProps = {
    color: "dark",
};

// Typechecking props for the ImageHighlightCard
ImageHighlightCard.propTypes = {
    image: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "error",
        "warning",
        "dark",
        "light",
    ]),
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
};

export default ImageHighlightCard;
