import React from "react";
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function FormCard({ title, subtitle, children }) {
    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            shadow="lg"
            borderRadius="xl"
            sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                    rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                height: "100%", // Add this line to make it fill the height
                boxSizing: "border-box", // Add this to include padding in the height calculation
            }}
        >
            <MKTypography variant="h3" textAlign="center" mb={2}>
                {title}
            </MKTypography>
            <MKTypography variant="body2" textAlign="center" color="text" mb={3}>
                {subtitle}
            </MKTypography>

            {children}
        </MKBox>
    );
}

FormCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default FormCard;