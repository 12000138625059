/* eslint-disable */
import React from "react";

// core components
import { Grid, Container } from "@mui/material";




import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// nodejs library that concatenates classes

// SwiperJS

import 'swiper/css';
import "swiper/css/navigation";

import ImageHighlightCard from "examples/Cards/ReviewCards/ImageHighlightCard";


import ImgJobAsCodeDocker from "assets/images/KyLy/UniCore/JobAsCodeDocker.webp";
import ImgTaskExecution from "assets/images/KyLy/UniCore/TaskExecution.webp";
import ImgUserPermissions from "assets/images/KyLy/UniCore/UserPermissions.webp";
import ImgEndpointOptions from "assets/images/KyLy/UniCore/EndpointOptions.webp";
import ImgResources from "assets/images/KyLy/UniCore/Resources.webp";



export default function SectionUniCoreKeyFeatures() {


    return (
        // <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 4 }} mx={-2} sx={{ minHeight: "80vh", paddingBottom: "10px" }}>
        <MKBox
            component="section"
            position="relative"
            mt={{ xs: 0, lg: 4 }}
            mx={{ xs: -5, md: -2 }}
            sx={{ minHeight: "50vh", paddingBottom: "10px" }}>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12} sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}>
                        <MKTypography variant="h3" color="dark" textAlign="center">
                            Managing your product and access to it simply & intuitive
                        </MKTypography>
                        <MKTypography variant="body2" color="dark" opacity={0.8} textAlign="center">
                            There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
                            at. That&apos;s my skill.
                        </MKTypography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} lg={5} mb={{ xs: 3, lg: 0 }}>
                        <ImageHighlightCard
                            image={ImgUserPermissions}
                            color="dark"
                            caption="User & Permissions"
                            text="UniCore features a simple to use permission construct, where any object / resource / endpoint / item in the UniCore framework can be granted permission to a single user, a group or on a role base. Manage users, groups and roles with only a few clicks." />
                    </Grid>
                    <Grid item xs={12} lg={5} mb={{ xs: 3, lg: 0 }}>
                        <ImageHighlightCard
                            image={ImgResources}
                            color="dark"
                            caption="Data Resources"
                            text="Files and data can be stored, retrieved and updated as resources using a resource adapter (FileServer, DataBase, AWS, Azure, GCloud). Each resource integrates into the permission system and can i.e. be provided only to customers belonging to a certain subscription tier." />
                    </Grid>

                    <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <ImageHighlightCard
                            image={ImgEndpointOptions}
                            color="dark"
                            caption="Dynamic Endpoints"
                            text="Configure custom endpoints to trigger pipelines, execute jobs, provide or collect data. All that is fully integrated with the user permission system and collects per-user information like call-count / ingress / egress that can be used to bill your services." />
                    </Grid>
                    <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <ImageHighlightCard
                            image={ImgTaskExecution}
                            color="dark"
                            caption="Job Execution"
                            text="Let your developers code repetitive tasks such that each user can execute these with a single click. Besides manual triggers, you can create CRON- or timing based job executions without the need of technical knowledge. Even complex pipelines can be configured and ran that way." />
                    </Grid>
                    <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                        <ImageHighlightCard
                            image={ImgJobAsCodeDocker}
                            color="dark"
                            caption="Code-based Jobs"
                            text="Not a C#/.NET company? No Problem! With our code-based job approach you can simply setup a pipeline to load your project (i.e. MATLAB, Python, R, ...) from your code repository, and execute it in a controlled environment." />
                    </Grid>
                </Grid>
            </Container>
        </MKBox >
    );
}
