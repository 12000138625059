/* eslint-disable */
import React from "react";
import { useRef, useState, useEffect } from "react";

// core components
import { Grid, Card, CardHeader, CardContent, Container, Stack } from "@mui/material";
import IconOption from "components/Options/IconOption";
import Icon from "@mui/material/Icon";

// nodejs library that concatenates classes
import styled from '@emotion/styled';
import bgPattern from "assets/images/shapes/pattern-lines.svg";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";

// Add these new imports
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function SectionUniCoreOverview() {
    const MacButton = styled.div`
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 8px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        background: ${props => props.background || 'transparent'};
    `;

    return (
        <MKBox position="relative" mt={{ xs: 0, lg: 4 }} mx={-2} sx={{ minHeight: "80vh", paddingBottom: "10px" }}>
            <Container>
                <Grid container>
                    <Grid item xs={12} lg={12}
                        sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}>
                        <MKTypography variant="h3" color="white">
                            Why use UniCore?
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8}>
                            Give your project a headstart and drastically reduce your time-to-market
                        </MKTypography>
                    </Grid>
                </Grid>

                <Grid container spacing={3} ml={-2} mr={-2} mt={{ xs: -12, md: -6 }} alignItems="stretch">
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            ml: { xs: -2, lg: "auto" },
                            mt: { xs: 6, lg: 0 },
                            display: 'flex',
                            alignItems: 'center'
                        }} >
                        <Stack spacing={2}>
                            <IconOption
                                icon="mediation"
                                content={
                                    <>
                                        Join data and workflow pipelines in one place.
                                        <br />
                                        UniCore.NET offers a job system automating your daily business.
                                    </>
                                }
                            />
                            <IconOption
                                icon="people"
                                content={
                                    <>
                                        App user- and subscription tier management made easy.
                                        <br />
                                        Take full control over the access you grant to your product.
                                    </>
                                }
                            />
                            <IconOption
                                icon="token"
                                content={
                                    <>
                                        Freedom choosing your hosting provider.
                                        <br />
                                        UniCore can be hosted either on-premise or any hyperscaler.
                                    </>
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{ display: 'flex', alignItems: 'center', mt: { xs: 0, lg: -8 } }}>
                        <Box
                            sx={{
                                backgroundColor: "rgba(30,30,30,0.9)",
                                borderRadius: "0.75rem",
                                overflow: "hidden",
                                width: '100%',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 16px",
                                    backgroundColor: "rgba(60,60,60,0.9)",
                                }}
                            >
                                <MacButton background="#ff5f56" />
                                <MacButton background="#ffbd2e" />
                                <MacButton background="#27c93f" />
                            </Box>
                            <SyntaxHighlighter
                                language="csharp"
                                style={vscDarkPlus}
                                customStyle={{
                                    padding: "1rem",
                                    fontSize: "0.875rem",
                                    margin: 0,
                                }}
                            >
                                {`using UniCore.Bundle.WebHost;

await new SampleWebHost().Run(args);

internal class SampleWebHost : AWebHost 
{  
    protected override async Task CustomAppConfig(WebApplication app, ...)
    {
        app.UCMapVersionedResource(
            endpoint:       "/CustomerData", 
            resourceName:   "ProjectA", 
            allowedFilters: ["Version"], 
            resType:        ResourceType.Json, 
            storageType:    ResourceStorageType.Database);
    }
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6} md={6} lg={6} >
                            <Card raised sx={{ backgroundColor: "rgba(30,30,30,0.9)" }}>
                                <CardContent>
                                    <MKTypography variant="h6" color="white">
                                        Gathering data and processing information is tedious
                                    </MKTypography>
                                    <MKTypography variant="h3" color="white">
                                        Free yourself from tedious tasks!
                                    </MKTypography>
                                    <MKTypography variant="body2" color="white">
                                        Sample tasks you can automate with UniCore:
                                        <ul>
                                            <li>Automate Web Scraping: Effortlessly gather data from any website without lifting a finger.</li>
                                            <li>Streamline Data Processing: Transform raw data into actionable insights with minimal effort.</li>
                                            <li>Integrate Cloud Providers: Seamlessly connect and synchronize data across multiple cloud platforms.</li>
                                        </ul>
                                    </MKTypography>
                                </CardContent>
                            </Card>
                        </Grid> */}
                </Grid>
            </Container>
        </MKBox >
    );
}







