/* eslint-disable */
import React, { useState } from 'react';

import { Button, Container, Grid, Icon, Stack } from "@mui/material";

import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";


// @material-ui/icons
import { Code, HelpOutline, VerifiedUser } from "@mui/icons-material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import IconOption from "components/Options/IconOption";

import SectionUniCoreKeyFeatures from "./Sections/SectionUniCoreKeyFeatures.js";
import SectionUniCoreOverview from "./Sections/SectionUniCoreOverview.js";
import SectionPipelineDiagram from "./Sections/SectionPipelineDiagram.js";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgPattern from "assets/images/shapes/pattern-lines.svg";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SectionBilling from './Sections/SectionBilling.js';

export default function SectionProductUniCore() {
    const code = `using UniCore.Bundle.WebHost;

await new SampleWebHost().Run(args);

internal class SampleWebHost : AWebHost { }`;

    const [activeTab, setActiveTab] = useState(0);
    const handleTabType = (event, newValue) => setActiveTab(newValue);

    return (
        <>
            <SectionUniCoreKeyFeatures />

            <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 4 }} mx={-2}>
                <Container>
                    <AppBar position="static" sx={{ pt: 4 }} >
                        <Tabs value={activeTab} onChange={handleTabType}>
                            <Tab label="Overview" icon={<Icon>info</Icon>} />
                            <Tab label="API Billing" icon={<Icon>attach_money</Icon>} />
                            <Tab label="Job Pipelines" icon={<Icon>timeline</Icon>} />
                        </Tabs>
                    </AppBar>
                    {activeTab == 0 ? <SectionUniCoreOverview /> : <></>}
                    {activeTab == 1 ? <SectionBilling /> : <></>}
                    {activeTab == 2 ? <SectionPipelineDiagram /> : <></>}
                </Container>
            </MKBox>
        </>
    );
}
