// @mui icons
import { Coffee, FavoriteOutlined } from "@mui/icons-material";


// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

import { AppConfig } from "global/config";

const date = new Date().getFullYear();

export default {
    brand: {
        name: AppConfig.Brand,
        image: logoCT,
        route: "/",
    },
    socials: [
        // {
        //   icon: <FacebookIcon />,
        //   link: "https://www.facebook.com/CreativeTim/",
        // },
        // {
        //   icon: <TwitterIcon />,
        //   link: "https://twitter.com/creativetim",
        // },
        // {
        //   icon: <GitHubIcon />,
        //   link: "https://github.com/creativetimofficial",
        // },
        // {
        //   icon: <YouTubeIcon />,
        //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
        // },
    ],
    menus: [
        {
            name: "company",
            items: [
                { name: "Home", href: "/" },
                // { name: "About Us", href: "/#team" },
            ],
        },
        {
            name: "Technical",
            items: [
                { name: "Impressum", href: "/impressum" },
            ],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date}, made with{" "}
            <FavoriteOutlined /> and {" "}
            <Coffee /> in NRW{" "}.
        </MKTypography>
    ),
};
