import React, { useState } from "react";
import FormCard from "./Components/FormCard";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import Popover from "@mui/material/Popover";

export default function SectionRegisterInterested() {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        try {
            const response = await fetch(form.action, {
                method: form.method,
                body: formData,
                mode: 'no-cors' // This might be necessary for cross-origin requests
            });
            if (response.status === 200) {
                setSuccess(true);
                form.reset();
            } else if (response.status === 302) {
                setConfirm(true);
                form.reset();
            } else {
                setError(false);
            }
        } catch (error) {
            console.error('Submission error:', error);
            setError(true);
            setSuccess(false);
        }
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderBrevoForm = () => {
        return (
            <>
                <MKBox sx={{ mt: -2 }}>
                    <MKTypography variant="body2" component="div" sx={{ textAlign: "center" }}>
                        <div id="sib-form-container">
                            {error && (
                                <div id="error-message" style={{ fontSize: "16px", textAlign: "left", fontFamily: "Helvetica, sans-serif", color: "#661d1d", backgroundColor: "#ffeded", borderRadius: "3px", borderColor: "#ff4949", maxWidth: "540px" }}>
                                    <MKTypography variant="body2" component="div">
                                        <svg viewBox="0 0 512 512">
                                            <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                                        </svg>
                                        <MKTypography variant="body2" component="span">
                                            Deine Anmeldung konnte nicht gespeichert werden. Bitte versuche es erneut.
                                        </MKTypography>
                                    </MKTypography>
                                </div>
                            )}

                            {success && (
                                <div id="success-message" style={{ fontSize: "16px", textAlign: "left", fontFamily: "Helvetica, sans-serif", color: "#085229", backgroundColor: "#e7faf0", borderRadius: "3px", borderColor: "#13ce66", maxWidth: "540px" }}>
                                    <MKTypography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <svg viewBox="0 0 512 512" style={{ width: '20px', height: '20px', marginRight: '10px' }}>
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" fill="currentColor" />
                                        </svg>
                                        <span>You successfully registered!</span>
                                    </MKTypography>
                                </div>
                            )}
                            {confirm && (
                                <div id="success-message" style={{ fontSize: "16px", textAlign: "left", fontFamily: "Helvetica, sans-serif", color: "#085229", backgroundColor: "#e7faf0", borderRadius: "3px", borderColor: "#13ce66", maxWidth: "540px" }}>
                                    <MKTypography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <svg viewBox="0 0 512 512" style={{ width: '20px', height: '20px', marginRight: '10px' }}>
                                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" fill="currentColor" />
                                        </svg>
                                        <span>You successfully registered! - We sent you a confirmation email, please follow the link in the email to confirm your subscription.</span>
                                    </MKTypography>
                                </div>
                            )}

                            {!success && (
                                <div id="sib-container" style={{ textAlign: "center", backgroundColor: "transparent", maxWidth: "540px", borderRadius: "3px", borderWidth: "0px", borderColor: "#C0CCD9", borderStyle: "solid", direction: "ltr" }}>
                                    <form id="sib-form" method="POST" action="https://7d7bb778.sibforms.com/serve/MUIFABstHBiIW3zODJcZsRxGeEMvJCcYFJsxRnm7SUxRUyEJG2gSay2GLSINnOU39h1TulMzhAShw4r5V7b-mRjAxF3zf47fTF9obY4dQPvnz5kNf8Fe6L1OqYwVUX-r2TxcpMBmDZtYHISrRXGTUusL2Ghr0eH4ZEHZrit9XMrvIHhh-oIzdwQhXz0cqXsnJjat6JkGd2-0OY0k" data-type="subscription" onSubmit={handleSubmit}>
                                        <MKInput
                                            type="email"
                                            id="EMAIL"
                                            name="EMAIL"
                                            label="Your Email"
                                            fullWidth
                                            required
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    backgroundColor: '#f0f0f0', // Light gray background
                                                    '& fieldset': {
                                                        borderColor: '#d0d0d0', // Light gray border
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#b0b0b0', // Slightly darker on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#909090', // Even darker when focused
                                                    },
                                                },
                                            }}
                                        />

                                        <div style={{ padding: "4px 0" }}>
                                            <div data-required="true">
                                                <div>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <label style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="OPT_IN"
                                                                    name="OPT_IN"
                                                                    required
                                                                    style={{ marginRight: '10px', marginTop: '3px' }}
                                                                />
                                                                <MKTypography variant="body2" component="span" style={{ fontSize: "14px", textAlign: "left", fontFamily: "Helvetica, sans-serif", color: "#3C4858", backgroundColor: "transparent" }}>
                                                                    I want to receive updates about UniCore and the KyberLyte newsletter and accept the
                                                                    <span
                                                                        onMouseEnter={handlePopoverOpen}
                                                                        style={{ color: 'blue', textDecoration: 'underline' }}>
                                                                        &nbsp;privacy policy
                                                                    </span>*.
                                                                    <span data-required="*" style={{ display: "inline" }}></span>
                                                                </MKTypography>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <MKTypography variant="caption" component="label" style={{ fontSize: "12px", textAlign: "left", fontFamily: "Helvetica, sans-serif", color: "#8390A4" }}>
                                                        You can unsubscribe any time via the link in our newsletter.
                                                    </MKTypography>
                                                </div>
                                            </div>
                                        </div>
                                        <Popover
                                            id="mouse-over-popover"
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}
                                            onMouseLeave={handlePopoverClose}>
                                            <div style={{ padding: "8px 0", backgroundColor: 'aliceblue' }}>
                                                <div style={{ direction: "ltr" }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div

                                                            style={{ marginRight: '10px', flexShrink: 0, cursor: 'pointer' }}>
                                                            <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                                <defs>
                                                                    <symbol id="svgIcon-sphere" viewBox="0 0 63 63">
                                                                        <path d="M31.54 0l1.05 3.06 3.385-.01-2.735 1.897 1.05 3.042-2.748-1.886-2.738 1.886 1.044-3.05-2.745-1.897h3.393zm13.97 3.019L46.555 6.4l3.384.01-2.743 2.101 1.048 3.387-2.752-2.1-2.752 2.1 1.054-3.377-2.745-2.1h3.385zm9.998 10.056l1.039 3.382h3.38l-2.751 2.1 1.05 3.387-2.744-2.091-2.743 2.091 1.054-3.377-2.752-2.117 3.385-.01zm-3.076 14.02l1.044 3.364h3.385l-2.743 2.09 1.05 3.392-2.744-2.097-2.743 2.097 1.052-3.377-2.752-2.117 3.385-.01zm-9.985 9.91l1.045 3.364h3.393l-2.752 2.09 1.05 3.393-2.744-2.097-2.743 2.097 1.05-3.383-2.751-2.1 3.384-.01zM31.45 55.01l1.044 3.043 3.393-.008-2.752 1.9L34.19 63l-2.744-1.895-2.748 1.891 1.054-3.05-2.743-1.9h3.384zm-13.934-3.98l1.036 3.364h3.402l-2.752 2.09 1.053 3.393-2.747-2.097-2.743 2.097 1.053-3.382-2.743-2.1 3.384-.01zm-9.981-9.91l1.045 3.364h3.398l-2.748 2.09 1.05 3.392-2.753-2.1-2.752 2.096 1.053-3.382-2.743-2.102 3.384-.009zM4.466 27.1l1.038 3.372H8.88l-2.752 2.097 1.053 3.387-2.743-2.09-2.748 2.09 1.053-3.387L0 30.472h3.385zm3.069-14.025l1.045 3.382h3.395L9.23 18.56l1.05 3.381-2.752-2.09-2.752 2.09 1.053-3.381-2.744-2.1h3.384zm9.99-10.056L18.57 6.4l3.393.01-2.743 2.1 1.05 3.373-2.754-2.092-2.751 2.092 1.053-3.382-2.744-2.1h3.384zm24.938 19.394l-10-4.22a2.48 2.48 0 00-1.921 0l-10 4.22A2.529 2.529 0 0019 24.75c0 10.47 5.964 17.705 11.537 20.057a2.48 2.48 0 001.921 0C36.921 42.924 44 36.421 44 24.75a2.532 2.532 0 00-1.537-2.336zm-2.46 6.023l-9.583 9.705a.83.83 0 01-1.177 0l-5.416-5.485a.855.855 0 010-1.192l1.177-1.192a.83.83 0 011.177 0l3.65 3.697 7.819-7.916a.83.83 0 011.177 0l1.177 1.191a.843.843 0 010 1.192z" fill="#0092FF"></path>
                                                                    </symbol>
                                                                </defs>
                                                            </svg>
                                                            <svg style={{ width: "63px", height: "63px" }}>
                                                                <use xlinkHref="#svgIcon-sphere"></use>
                                                            </svg>
                                                        </div>
                                                        <MKTypography variant="body2" sx={{ fontSize: "10px", p: 2, maxWidth: 300 }}>
                                                            Wir verwenden Brevo als unsere Marketing-Plattform. Indem du das Formular absendest, erklärst du dich einverstanden, dass die von dir angegebenen persönlichen Informationen an Brevo zur Bearbeitung übertragen werden gemäß den <a href="https://www.brevo.com/de/legal/privacypolicy/" target="_blank" rel="noopener noreferrer">Datenschutzrichtlinien von Brevo.</a>
                                                        </MKTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover>

                                        <div style={{ padding: "8px 0", display: "flex", justifyContent: "center" }}>
                                            <div style={{ textAlign: "left" }}>
                                                <MKButton sx={{ marginTop: 2 }} color="dark" type="submit" >
                                                    Subscribe
                                                </MKButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </MKTypography>
                </MKBox>
            </>
        );
    }

    return (
        <FormCard
            title="Stay Informed"
            subtitle="Be the first to know when we publicly launch and join our newsletter for exclusive updates and early access opportunities."
            sx={{ height: '100%' }}
        >
            {renderBrevoForm()}
        </FormCard>
    );
}