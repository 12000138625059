/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ImgFullLogo from "assets/images/KyLy/Logo_Astronaut_Blue.svg";

function DefaultFooter({ content }) {
    const { brand, socials, menus, copyright } = content;

    const renderFooterMenu = ({ name: title, items }) => (
        <Grid
            key={title}
            item
            xs={6}
            md={3}
            sx={{
                mb: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'flex-start',
                height: '100%'  // Add this to ensure full height
            }}
        >
            <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
                textAlign="center"
            >
                {title}
            </MKTypography>
            <MKBox
                component="ul"
                p={0}
                m={0}
                sx={{
                    listStyle: "none",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'  // Add this to ensure full width
                }}
            >
                {items.map(({ name, route, href }) => (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25} width="100%" textAlign="center">
                        {href ? (
                            <MKTypography
                                component="a"
                                href={href}
                                target="_blank"
                                rel="noreferrer"
                                variant="button"
                                fontWeight="regular"
                                textTransform="capitalize"
                                display="block"  // Add this to ensure block display
                            >
                                {name}
                            </MKTypography>
                        ) : (
                            <MKTypography
                                component={Link}
                                to={route}
                                variant="button"
                                fontWeight="regular"
                                textTransform="capitalize"
                                display="block"  // Add this to ensure block display
                            >
                                {name}
                            </MKTypography>
                        )}
                    </MKBox>
                ))}
            </MKBox>
        </Grid>
    );

    return (
        <MKBox component="footer">
            <Grid container spacing={3}>
                <Grid item xs={12} md={1}></Grid>
                {menus.slice(0, menus.length / 2).map(renderFooterMenu)}

                <Grid item xs={12} md={4} sx={{ mb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MKBox display="flex" flexDirection="column" alignItems="center" width="100%">
                        <MKBox component="img" src={ImgFullLogo} alt={brand.name} maxWidth="18rem" mb={2} />
                    </MKBox>
                </Grid>

                {menus.slice(menus.length / 2).map(renderFooterMenu)}
                <Grid item xs={12} md={1}></Grid>

                <MKBox display="flex" alignItems="center" mt={3}>
                    {socials.map(({ icon, link }, key) => (
                        <MKTypography
                            key={link}
                            component="a"
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            variant="h5"
                            color="dark"
                            opacity={0.8}
                            mr={key === socials.length - 1 ? 0 : 2.5}
                        >
                            {icon}
                        </MKTypography>
                    ))}
                </MKBox>
            </Grid>

            <MKBox display="flex" flexDirection="column" alignItems="center" width="100%" mt={2} mb={2}>
                {copyright}
            </MKBox>
        </MKBox>
    );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
    content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
