import React from "react";
import MKButton from "components/MKButton";
import FormCard from "./Components/FormCard";

function SectionContact() {
    return (
        <FormCard
            title="Interested in a demo? - Contact us!"
            subtitle="UniCore.NET sounds promising to fuel your next project or to modernize your legacy corporate code but you&apos;re not fully convinced yet?
                Let us get in touch and schedule a demo to further discuss the benefit for your business.

                We will get back to you shortly."
            sx={{ height: '100%' }}
        >
            <MKButton sx={{ marginTop: 2 }} color="dark" href="mailto:alexander.schnitzler@kyberlyte.de?subject=You%20X%20KyberLyte%20-%20Let%27s%20get%20in%20touch">Send Message</MKButton>
        </FormCard>
    );
}

export default SectionContact;
