/* eslint-disable */
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import createEngine, {
    DiagramModel,
    DefaultNodeModel,
    DefaultPortModel,
    DefaultLinkFactory,
    DefaultLinkModel,
    DiagramEngine,
    AbstractReactFactory,
    NodeModel,
    NodeModelGenerics,
    PortWidget,
    PortModelAlignment,
    LinkModel,
    PortModel
} from '@projectstorm/react-diagrams';
import { useEffect } from 'react';

export class AdvancedPortModel extends PortModel {
    constructor(alignment, PortType) {
        super({
            type: 'advanced',
            name: alignment,
            alignment: alignment,
        });
    }

    createLinkModel() {
        //return new DefaultLinkModel();
        return new AdvancedLinkModel();
    }
}

export class AdvancedLinkModel extends DefaultLinkModel {
    constructor() {
        super({
            type: 'advanced',
            width: 10,
        });
    }
}


export class AdvancedLinkFactory extends DefaultLinkFactory {
    Keyframes = keyframes`
		from {
			stroke-dashoffset: 24;
		}
		to {
			stroke-dashoffset: 0;
		}
	`;

    selected = css`
		stroke-dasharray: 10, 2;
		animation: ${this.Keyframes} 1s linear infinite;
	`;

    Path = styled.path`
        ${(p) => this.selected};
		fill: none;
		pointer-events: auto;
	`;

    constructor() {
        super('advanced');
    }

    generateModel() {
        return new AdvancedLinkModel();
    }

    generateLinkSegment(model, selected, path) {
        return (
            <g>
                <this.Path
                    selected={true}
                    stroke={model.getOptions().selectedColor}
                    strokeWidth={3}
                    d={path}
                />
            </g>
        );
    }
}





