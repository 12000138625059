/* eslint-disable */
import React from "react";

import PropTypes from "prop-types";
import footerRoutes from "footer.routes";

import DefaultFooter from "examples/Footers/DefaultFooter";

export default function DefaultCompanyFooter() {
    return (
        <DefaultFooter content={footerRoutes} />
    );
}
