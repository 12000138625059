/* eslint-disable */
import {
    AbstractModelFactory, AbstractReactFactory, DefaultLinkModel, DiagramEngine, LinkModel, NodeModel,
    NodeModelGenerics, PortModel, PortModelAlignment, PortWidget
} from "@projectstorm/react-diagrams";
import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { renderToStaticMarkup } from "react-dom/server";
import { AdvancedLinkModel } from "../Links/AdvancedLink";


export function ImageNodeWidget(props) {
    const [imgBase64, setImgBase64] = useState("");

    useEffect(() => {
        fetch(props.node.image)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        setImgBase64(reader.result?.toString());
                    }
                })
            });
    }, []);

    var size = 10;
    if (props.size) {
        size = props.size;
    }


    const elements = {
        Port: styled.div`
            width: 8px;
            height: 8px;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 1);
            }
        ` };

    return (
        <div
            className={'image-node'}
            style={{
                position: 'relative',
                width: props.size,
                height: props.size
            }}
        >
            {/* <img width={this.props.size} src={this.props.node.image} alt="..." /> */}
            <svg
                //xmlns:xlink="http://www.w3.org/1999/xlink"
                width={props.size}
                dangerouslySetInnerHTML={{
                    __html:
                        `<g id="Layer_2">
                                <image width="${props.size}" xlink:href="${imgBase64}" />
                            </g>`
                }}
            />
            {props.node.getPort(PortModelAlignment.LEFT) ?
                <PortWidget
                    style={{
                        top: size / 2 - 8,
                        left: -8,
                        position: 'absolute'
                    }}
                    port={props.node.getPort(PortModelAlignment.LEFT)}
                    engine={props.engine}
                >
                    <elements.Port />
                </PortWidget> : (<></>)
            }
            {props.node.getPort(PortModelAlignment.TOP) ?
                <PortWidget
                    style={{
                        left: size / 2 - 8,
                        top: -8,
                        position: 'absolute'
                    }}
                    port={props.node.getPort(PortModelAlignment.TOP)}
                    engine={props.engine}
                >
                    <elements.Port />
                </PortWidget> : (<></>)
            }
            {props.node.getPort(PortModelAlignment.RIGHT) ?
                <PortWidget
                    style={{
                        left: size - 8,
                        top: size / 2 - 8,
                        position: 'absolute'
                    }}
                    port={props.node.getPort(PortModelAlignment.RIGHT)}
                    engine={props.engine}
                >
                    <elements.Port />
                </PortWidget> : (<></>)
            }

            {props.node.getPort(PortModelAlignment.BOTTOM) ?
                <PortWidget
                    style={{
                        left: size / 2 - 8,
                        bottom: 0,
                        position: 'absolute'
                    }}
                    port={props.node.getPort(PortModelAlignment.BOTTOM)}
                    engine={props.engine}
                >
                    <elements.Port />
                </PortWidget> : (<></>)
            }
        </div>
    );
}




export class ImageNodeModel extends NodeModel {
    image = "";
    size = 100;

    constructor(image, size) {
        const reactSvgComponentToMarkupString = (Component, color) =>
            `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(Component)
                .replace("<svg ", '<svg xmlns="http://www.w3.org/2000/svg" ')
                .replace('<path', '<path style="fill: ' + color + '"'))}`;

        super({ type: 'image' });
        if (typeof image === 'string') {
            this.image = image;
        }
        else {
            this.image = reactSvgComponentToMarkupString(image, "#b9b9b9");
        }

        if (size) this.size = size;
    }
}



export class ImageNodeFactory extends AbstractReactFactory {
    constructor() { super('image'); }

    generateReactWidget(event) { return (<ImageNodeWidget engine={this.engine} size={event.model.size} node={event.model} />) };
    generateModel(event) { return new ImageNodeModel("NOT FOUND"); }
}

export class ImageNodePortModel extends PortModel {
    constructor(alignment, PortType) {
        super({
            type: 'image',
            name: alignment,
            alignment: alignment,
        });
    }
    createLinkModel() { return new AdvancedLinkModel(); }

    link(port, factory) {
        let link = this.createLinkModel();
        link.setSourcePort(this);
        link.setTargetPort(port);
        return link;
    }
}

