/*eslint-disable*/ import React from "react";
import { useRef, useState, useEffect } from "react";
// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";

import { Card, Container, Grid } from "@mui/material";
import Divider from '@mui/material/Divider';

import MKBox from "components/MKBox/index.js";
import MKButton from "components/MKButton/index.js";
import MKTypography from "components/MKTypography/index.js";



// Custom components
import DefaultNavbar from "examples/Navbars/DefaultNavbar/index.js";
import DefaultCompanyFooter from "elements/footer/DefaultCompanyFooter.js";

// Sections for this page
import SectionProductUniCore from "./Sections/UniCore/SectionProductUniCore.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionContact from "./Sections/SectionContact.js";
import SectionRegisterInterested from "./Sections/SectionRegisterInterested.js";

import { AppConfig } from "global/config.js";


// Images
import bgImage from "assets/images/KyLy/DigitalBanner1.webp";

import routes from "routes.js";
import FormCard from "./Sections/Components/FormCard.js";
import SectionKyberLyteServices from "./Sections/SectionKyberLyteServices.js";

export default function LandingPage({ ...rest }) {
    const headerRef = useRef(null);
    const typedJSRef = useRef(null);

    // Setting up rellax
    useEffect(() => {
        const parallax = new Rellax(headerRef.current, {
            speed: -6,
        });

        return () => parallax.destroy();
    }, []);

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky hideWhenScrolling />
            <MKBox
                ref={headerRef}
                minHeight="105vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.3),
                            rgba(gradients.dark.state, 0.7)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={10}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Start your data driven projects right here
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            It doesn't take much! Set up a fully billable REST-API to provide your data product to your consumers... and only to them.
                            With a full authorization and permission system, UniCore manages access to API endpoints automatically!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 1, md: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >

                <div id="UniCore" >
                    <SectionProductUniCore />
                </div>

                {/* <div id="team" >
                    <SectionTeam />
                </div> */}

                <SectionKyberLyteServices />

                <MKBox
                    position="relative"
                    variant="gradient"
                    bgColor="dark"
                    mt={{ xs: 0, lg: 4 }}
                    mx={-2}
                    sx={{
                        minHeight: "80vh",
                        borderBottomLeftRadius: { xs: "2rem" },
                        borderBottomRightRadius: { xs: "2rem" },
                        marginBottom: { xs: "-2rem", md: "-3rem" },
                        paddingBottom: { xs: "2rem" },
                        overflow: "hidden"
                    }}>
                    <Container>
                        <Grid container spacing={2} mt={3} sx={{ height: '100%' }}>
                            <Grid item xs={12} lg={5} sx={{ height: '100%' }}>
                                <SectionRegisterInterested sx={{ height: '100%' }} />
                            </Grid>
                            <Grid item xs={0} lg={2} />
                            <Grid item xs={12} lg={5} sx={{ height: '100%' }}>
                                <SectionContact sx={{ height: '100%' }} />
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox>
            </Card >

            <MKBox pt={6} px={1} mt={6}>
                <DefaultCompanyFooter />
            </MKBox>
        </>
    );
}
