/*eslint-disable*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultCompanyFooter from "elements/footer/DefaultCompanyFooter";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/KyLy/DigitalBanner1.webp";

import SectionText from "./Sections/SectionText";


import { AppConfig } from "global/config";

export default function ImpressumPage() {
    const headerRef = useRef(null);

    // Setting up rellax
    useEffect(() => {
        const parallax = new Rellax(headerRef.current, {
            speed: -6,
        });

        return () => parallax.destroy();
    }, []);

    return (<>
        <DefaultNavbar
            routes={routes} />
        <MKBox
            ref={headerRef}
            minHeight="55vh"
            width="100%"
            sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
            }}
        >
            <Container>
                <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
                    <MKTypography
                        variant="h1"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                                fontSize: size["3xl"],
                            },
                        })}
                    >
                        Impressum
                    </MKTypography>
                    <MKTypography
                        variant="body1"
                        color="white"
                        opacity={0.8}
                        mb={2}
                        mr={{ xs: 0, sm: 6 }}
                        pr={{ xs: 0, sm: 6 }}
                    >
                        Angaben gemäß §5 TMG
                    </MKTypography>
                </Grid>
            </Container>
        </MKBox>
        <Card
            sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
        >
            <SectionText />
        </Card>
        <MKBox pt={6} px={1} mt={6}>
            <DefaultCompanyFooter />
        </MKBox>
    </>
    );
}
